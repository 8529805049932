<template>
  <div style="width: 1280px" data-cy="page-plan-success">
    <div class="flex flex-col justify-center content-center box-style">
      <div
        class="text-3xl text-dark-purple font-bold text-center pt-3"
        data-cy="add-success-header"
        aria-label="Your plan was successfully submitted"
        ref="planSuccess"
      >
      
        Success!
      </div>
      <div class="py-2 text-center">
        Your personal + professional development plan (PPDP) has been
        successfully submitted.
      </div>
      <div class="border border-cool-gray my-3 mt-5"></div>
      <div class="pt-4 place-self-center pb-2">
        <div class="px-8">
          <div class="pb-1 thirdhead-text text-center">
            Next, add your PPDP meeting
          </div>
          <div class="pb-4 text-base text-center">
            Now's a great time to make and entry for the meeting with your
            mentor when you reviewed your plan.
          </div>
        </div>
      </div>
      <div class="relative place-self-center">
        <button
          class="bg-purple text-white rounded-3xl py-1 px-3 hover:bg-dark-purple hover:text-white hover:no-underline bottom-0 float-right"
          @click="$router.push('/fieldworks/add/ppdp_meeting')"
        >
          Add PPDP Meeting
        </button>
      </div>
      <!--      <div class="pb-2">
        Check your fieldwork dashboard daily for updates regarding this
        fieldwork log!
      </div>-->
    </div>
  </div>
</template>

<script>
// TODO: THIS PAGE SHOULD ONLY BE ACCESSIBLE AFTER ADDING A LOG
// TODO: ADD ROUTE GUARD TO THIS PAGE... Maybe? Route guard should include a check for JWT
export default {
  name: "PlanSuccess",
  mounted() {
    this.$refs.planSuccess.focus(); 
  }
};
</script>

<style scoped></style>
